import styled from 'styled-components'
import { Flex, Text, Box, Image } from '@fullfacing/schoolbus'

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'flex-start'
})`
  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
  }
`

export const Wrapper = styled(Box).attrs({
  position: 'relative',
  width: '100%'
})`
  @media (max-width: 1200px) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const Title = styled(Text).attrs({
  as: 'h2',
  fontSize: 'small',
  color: 'grayscale.default',
  fontWeight: 'extrabold'
})`
  text-transform: uppercase;
`

export const Name = styled(Text).attrs({
  as: 'h2',
  fontSize: 'xxlarge',
  fontWeight: 'bold',
  mb: '0.3em'
})`
  text-transform: capitalize;
`

export const Address = styled(Text).attrs({
  fontSize: 'small',
  mb: '1em'
})`
  @media (max-width: 1200px) {
    text-align: center;
  }
`

export const Contact = styled(Flex).attrs({
  as: 'a',
  alignItems: 'center',
  justifyContent: 'space-between',
  mr: '2em',
  letterSpacing: 'normal',
  color: 'grayscale.dark',
  fontWeight: 'semi',
  fontSize: 'small'
})`
  text-decoration: none;
`

export const HeaderImage = styled(Image).attrs({
  src: 'https://cdn.fullfacing.io/images/9d7af255-b735-49f8-9446-ec02610f8a52.svg',
  alt: '',
  height: '10em',
  width: '10em',
  objectFit: 'contain'
})`
  @media (min-width: 1200px) {
    margin-right: 2em;
  }
`
