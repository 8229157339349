import styled from 'styled-components'

import { Box } from '@fullfacing/schoolbus'

export const StyledBox = styled(Box).attrs(({ isActive, ...otherProps }) => ({
  p: '1.875rem',
  bg: isActive ? 'white' : '#fbfbfb',
  borderRadius: 'large',
  border: 'light',
  ...otherProps
}))`
  transition: box-shadow 100ms ease-out;

  :hover {
    ${({ isActive, theme }) => isActive && `
      box-shadow: ${theme.shadows.hover};
    `};
  }
`
