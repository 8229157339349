import { Component } from 'react'
import dynamic from 'next/dynamic'
import { PageProgressMethods } from '@fullfacing/ff-web-next-utils'
import toast from 'react-hot-toast'
import { Flex, IconButton, OverflowMenu, Box } from '@fullfacing/schoolbus'

import Card from '@lib/styled/card'
import Loader from './components/loader'
import api from '@api'

import {
  Address,
  Contact,
  Container,
  Name,
  Title,
  Wrapper,
  HeaderImage
} from './styles'

import clientContainer from '@lib/containers/client'
import countryContainer from '@lib/containers/country'

import patchMerge from '@lib/utils/patch-merge'
import objectHasEntries from '@lib/utils/object-has-entries'
import { HO_URL } from '@lib/config/constants'

const AddClientModal = dynamic(() => import('@lib/modals/add-client-modal'))

const initClientValues = {
  id: '',
  name: '',
  emailAddress: '',
  number: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  region: '',
  country: ''
}

class ProfileCard extends Component {
  state = {
    showProfileModal: false,
    ...initClientValues
  }

  componentDidMount () {
    this.fetch()
  }

  fetch = async () => {
    const params = new URLSearchParams(window.location.search)
    const clientId = params.get('clientId')

    try {
      const { id, name, contact, address } = clientId
        ? await api.clients.getById(clientId)
        : clientContainer.state

      const { emailAddress = '', number = '', address: clientAddress = {} } =
        contact || {}

      const {
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        region,
        countryCode
      } = address || clientAddress

      this.setState({
        name,
        id,
        emailAddress,
        number,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        region: { label: region, value: region },
        country: countryContainer.getByCountryCode(countryCode)
      })
    } catch (error) {
      console.error(error)
      toast.error(
        'We were unable to load the client details. Please try again.',
        { error, errorType: 'view' }
      )
    }
  }

  handleProfileModalToggle = () =>
    this.setState({ showProfileModal: !this.state.showProfileModal })

  async handleSubmit ({ updates, initialValues }) {
    PageProgressMethods.start()
    const { id } = this.state
    const { address: clientAddress = {} } = clientContainer.state
    const hasClientAddress = objectHasEntries(clientAddress)

    const {
      addressLine1,
      addressLine2,
      addressLine3,
      region,
      country,
      city,
      emailAddress,
      number,
      ...otherValues
    } = updates

    const fullAddress = {
      type: 'Commercial',
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      region: region && region.value,
      countryCode: country && country.value
    }

    const patchedAddress = patchMerge(fullAddress, {
      type: 'Commercial',
      addressLine1: initialValues.addressLine1,
      addressLine2: initialValues.addressLine2,
      addressLine3: initialValues.addressLine3,
      city: initialValues.city,
      region: initialValues.region?.value,
      countryCode: initialValues.country?.value
    })

    const updatedValues = patchMerge(otherValues, initialValues)

    try {
      const clientBody = {
        ...updatedValues,
        contact: { emailAddress, name: otherValues.name, number },
        address: hasClientAddress ? patchedAddress : fullAddress
      }

      const shouldUpdateClient = objectHasEntries(clientBody)

      if (shouldUpdateClient) {
        const clientData = await api.clients.patch(id, clientBody)
        await clientContainer.set({ id, ...clientData })
      }

      toast('Sorted! The client profile has been updated.')
      this.setState({ showProfileModal: false, ...updates })
    } catch (error) {
      console.error(error)
      toast.error(
        'We were unable to update the client profile. Please try again.',
        { error, errorType: 'edit' }
      )
    } finally {
      PageProgressMethods.done()
    }
  }

  getAddress = () => {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      postalCode,
      region
    } = this.state

    return [
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      postalCode,
      region.value
    ]
      .filter(val => !!val)
      .join(', ')
  }

  render () {
    const { isAdmin, withBack } = this.props

    const {
      showProfileModal,
      emailAddress,
      number,
      addressLine1,
      city,
      addressLine2,
      addressLine3,
      region,
      name,
      country,
      id
    } = this.state

    const initialValues = {
      name,
      emailAddress,
      number,
      addressLine1,
      city,
      addressLine2,
      region,
      addressLine3,
      country
    }

    const overflowOptions = [
      isAdmin && HO_URL && {
        name: 'Manage in HO',
        href: `${HO_URL}?clientId=${id}`,
        target: 'blank'
      },
      {
        name: 'Edit',
        onClick: this.handleProfileModalToggle
      }
    ]

    return (
      <Card margin='1em 0 0'>
        <Container>
          <HeaderImage />

          <Wrapper>
            <Flex mb='1.5em' alignItems='center'>
              {withBack && (
                <IconButton
                  variant='clear'
                  icon='ArrowLeft'
                  ml='-.7em'
                  href='/'
                />
              )}
              <Title>Client Profile</Title>
              <Box position='absolute' right='1em' top='1em'>
                <OverflowMenu options={overflowOptions} />
              </Box>
            </Flex>

            {!id && <Loader />}
            {id && (
              <>
                <Name>{name}</Name>
                <Address>{this.getAddress()}</Address>
                <Flex flexWrap='wrap'>
                  <Contact href={`tel:${number}`}>
                    <IconButton variant='clear' icon='Phone' />
                    {number}
                  </Contact>
                  <Contact href={`mailto:${emailAddress}`}>
                    <IconButton variant='clear' icon='Email' />
                    {emailAddress}
                  </Contact>
                </Flex>

                <AddClientModal
                  initialValues={initialValues}
                  variant='Save'
                  isOpen={showProfileModal}
                  onClose={this.handleProfileModalToggle}
                  onSubmit={(updates) =>
                    this.handleSubmit({ updates, initialValues })}
                />
              </>
            )}
          </Wrapper>
        </Container>
      </Card>
    )
  }
}

export default ProfileCard
