import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

export const Container = styled(Flex).attrs(({ isActive, isLocked, ...otherProps }) => ({
  flexDirection: 'column',
  width: 'calc(25% - 0.7rem)',
  p: '0.625rem',
  m: '0 0.7rem 0.7rem 0',
  bg: isActive ? 'white' : 'grayscale.xxlight',
  boxShadow: 'itemContainer',
  border: 'light',
  borderRadius: 'small',
  ...otherProps
}))`
  transition: box-shadow 100ms ease-out;
  :hover {
    ${({ isLocked, isActive, theme }) => isActive && !isLocked && `
      box-shadow: ${theme.shadows.hover};
    `}
  }
`

export const Label = styled(Flex).attrs({
  alignItems: 'center'
})`
  > svg {
    height: 1.25rem;
  }
`
