import React from 'react'

import { theme, SelectWithoutFormik } from '@fullfacing/schoolbus'

import PlatformJourneyAccessCard from '../platform-access-card'

const { colors, fontWeights } = theme

const SELECT_STYLE_OVERRIDES = {
  variant: 'light',
  marginTop: '0.3rem',
  minWidth: '100%',
  width: '100%',
  controlStyle: {
    padding: 0,
    color: colors.grayscale.dark,
    fontWeight: fontWeights.medium,
    backgroundColor: colors.transparent
  },
  optionStyle: {
    padding: '0.6rem 1.25rem',
    color: colors.grayscale.dark,
    backgroundColor: colors.white
  },
  selectedStyle: {
    backgroundColor: colors.white,
    fontWeight: fontWeights.bold,
    color: colors.black
  }
}

const JourneyAccessCard = ({
  isActive,
  isLocked,
  label,
  selectOptions,
  selectValue,
  selectDefaultValue,
  handleSelect,
  ...otherProps
}) => {
  const isInactiveTile = isLocked || !isActive

  return (
    <PlatformJourneyAccessCard label={label} isActive={isActive} isLocked={isLocked} {...otherProps}>
      <SelectWithoutFormik
        options={selectOptions}
        disabled={isInactiveTile}
        onChange={handleSelect}
        name={label}
        defaultValue={selectDefaultValue}
        value={selectValue}
        {...SELECT_STYLE_OVERRIDES}
      />
    </PlatformJourneyAccessCard>
  )
}

JourneyAccessCard.defaultProps = {
  label: '',
  selectOptions: []
}

export default JourneyAccessCard
