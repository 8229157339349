import React from 'react'

import {
  Flex,
  Text,
  Card,
  SearchInput,
  DropdownToggle,
  IconButton,
  Box,
  Pill,
  OverflowMenu
} from '@fullfacing/schoolbus'

import { StyledChip } from './styles'

const LOCATIONS = {
  above: 'above',
  inline: 'inline',
  below: 'below'
}

const getPillVariant = count => {
  return typeof count === 'number' ? 'secondary' : 'clear'
}

const CardActions = ({
  title,
  description,
  isViewState,
  onSearch,
  onSearchExpand,
  searchExpanded,
  selectOptions,
  onSelectChange,
  selectedOption,
  onDownload,
  filterComponent,
  onAddClick,
  onAddHref,
  onEdit,
  onAddOptions,
  optionsIcon,
  preActions,
  postActions,
  additionalActions,
  aboveActions,
  tabs,
  onTabClick,
  children,
  actionsLocation = LOCATIONS.inline,
  tabsLocation = LOCATIONS.above,
  secondaryTabs,
  selectedText,
  renderHeader,
  count,
  countVariant = 'default',
  ...otherProps
}) => {
  const cardTitle =
    countVariant === LOCATIONS.inline
      ? (
        <>
          <Text as='span' fontWeight='inherit' color='black' mr='0.5em'>
            {count}
          </Text>
          {title}
        </>
        )
      : (
          title
        )

  const tabsComponent = (tabsArr) =>
    tabsArr?.map(({ active, text, key = text, onRemove, value, ...otherProps }) => (
      <Pill
        key={key}
        mr='1em'
        variant={active ? 'primary' : getPillVariant(value)}
        count={value}
        value={key}
        {...(onTabClick && { onClick: onTabClick })}
        {...otherProps}
      >
        <Flex alignItems='center'>
          {text}
          {onRemove && (
            <IconButton
              onClick={onRemove}
              variant='clear'
              icon='Close'
              ml='1em'
              mr='-1em'
              label='Remove'
            />
          )}
        </Flex>
      </Pill>
    ))

  const selectedComponent = selectedText && (
    <StyledChip variant='primary' label={selectedText} />
  )

  const actions = (
    <>
      {postActions && postActions}
      {selectedText &&
        actionsLocation === LOCATIONS.inline &&
        selectedComponent}
      {selectOptions && (
        <DropdownToggle
          onChange={onSelectChange}
          options={selectOptions}
          value={selectedOption}
          ml='1.25em'
        />
      )}
      {onSearch && (
        <SearchInput
          onChange={onSearch}
          onExpand={onSearchExpand}
          variant={searchExpanded ? 'expanded' : 'default'}
          aria-label={`Search ${title}`}
          ml='1.25em'
        />
      )}
      {filterComponent && <Box ml='1.25em'>{filterComponent}</Box>}
      {onDownload && (
        <IconButton
          icon='Download'
          variant='clear'
          onClick={onDownload}
          aria-label={`Download ${title}`}
          ml='1.25em'
        />
      )}
      {preActions}
      {(onAddClick || onAddHref) && !isViewState && (
        <IconButton
          ml='1.25em'
          aria-label={`Add ${title}`}
          {...(onAddHref ? { href: onAddHref } : { onClick: onAddClick })}
        />
      )}
      {onAddOptions && (
        <OverflowMenu
          ml='1.25em'
          trigger={<IconButton icon={optionsIcon || 'Pencil'} />}
          options={onAddOptions}
        />
      )}
      {onEdit && !isViewState && (
        <IconButton
          ml='1.25em'
          onClick={onEdit}
          icon='Pencil'
          aria-label={`Edit ${title}`}
        />
      )}
      {additionalActions}
    </>
  )

  const hasPreHeader =
    (tabs && tabsLocation === LOCATIONS.above) ||
    actionsLocation === LOCATIONS.above

  const preHeaderComponent = hasPreHeader && (
    <Flex mb='2.5em' width='100%' justifyContent='space-between'>

      {tabs && tabsLocation === LOCATIONS.above && (
        <Flex>
          {tabsComponent(tabs)}
        </Flex>
      )}
      {aboveActions}
      {actionsLocation === LOCATIONS.above && (
        <Flex ml='auto' alignItems='center'>
          {actions}
        </Flex>
      )}
    </Flex>
  )

  return (
    <Card
      title={cardTitle}
      aria-label={`${cardTitle} card`}
      {...otherProps}
      {...(actionsLocation === LOCATIONS.inline && { actions })}
      {...(description && !isViewState && { description })}
      {...(preHeaderComponent && { preHeaderComponent })}
      {...(typeof count === 'number' &&
        countVariant === 'default' && { count })}
      {...(renderHeader && {
        renderHeader: values => renderHeader({ ...values, actions })
      })}
    >
      {([tabsLocation, actionsLocation].includes(LOCATIONS.below) ||
        secondaryTabs
      ) && (
        <Flex alignItems='center' ml='auto' mb='2.5em'>
          {tabsLocation === LOCATIONS.below && tabsComponent(tabs)}
          {secondaryTabs && tabsComponent(secondaryTabs)}
          {actionsLocation === LOCATIONS.below && (
            <Flex alignItems='center' ml='auto'>
              {selectedComponent}
              {actions}
            </Flex>
          )}
        </Flex>
      )}
      {children}
    </Card>
  )
}

export default CardActions
