import React from 'react'
import { Th } from '@fullfacing/schoolbus'

const cellContentMap = {
  noIcon: '',
  number: '#',
  settings: ''
}

const styleOverrides = {
  th: { width: '1rem', padding: '.4em' }
}

const SettingsHeader = ({ variant, ...otherProps }) => (
  <Th center style={styleOverrides.th} {...otherProps}>
    {cellContentMap[variant]}
  </Th>
)

SettingsHeader.defaultProps = {
  variant: 'settings'
}

export default SettingsHeader
