import { PageProgressMethods } from '@fullfacing/ff-web-next-utils'
import toast from 'react-hot-toast'

export const handleAsyncFunctionLoad = async (
  func,
  errorMessage,
  setSubmitting
) => {
  PageProgressMethods.start()
  try {
    return await func()
  } catch (error) {
    console.error(error)
    errorMessage && toast.error(errorMessage, { error })
  } finally {
    setSubmitting && setSubmitting(false)
    PageProgressMethods.done()
  }
}
