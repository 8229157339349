import React from 'react'
import ContentLoader from 'react-content-loader'
import { theme } from '@fullfacing/schoolbus'

const Loader = () => (
  <div>
    <ContentLoader
      height={130}
      width={500}
      speed={1}
      viewBox='0 0 500 130'
      backgroundColor={theme.colors.grayscale.xlight}
      foregroundColor={theme.colors.grayscale.xxlight}
    >
      <rect x='15' y='10' rx='4' ry='4' width='120' height='10' />
      <rect x='15' y='40' rx='3' ry='3' width='140' height='10' />
      <rect x='15' y='80' rx='3' ry='3' width='500' height='10' />
      <rect x='15' y='120' rx='3' ry='3' width='170' height='10' />
      <rect x='200' y='120' rx='3' ry='3' width='170' height='10' />
    </ContentLoader>
  </div>
)

export default Loader
