import React from 'react'

import { Text, Flex, OverflowMenu, Button, IconButton, StatusPill } from '@fullfacing/schoolbus'

import { StyledBox } from './styles'

const getTileWidth = numOfTiles => 100 / numOfTiles

const AccountOverviewCard = ({
  title,
  options,
  tiles,
  isActive,
  buttonLabel,
  buttonOnClick,
  ...otherProps
}) => {
  const tileWidth = `calc(${getTileWidth(tiles.length)}% - 1rem)`

  return (
    <StyledBox isActive={isActive} {...otherProps}>
      {(title || options) && (
        <Flex justifyContent='space-between' alignItems='center' mb='1.5rem'>
          <Text fontSize='large' fontWeight='semi' color='black'>
            {title}
          </Text>

          <Flex alignItems='center'>
            {buttonLabel && (
              <Button
                size='small'
                variant='secondary'
                onClick={buttonOnClick}
              >{buttonLabel}
              </Button>
            )}

            {!!options.length && (
              <OverflowMenu ml='1rem' options={options} />
            )}
          </Flex>
        </Flex>
      )}

      <Flex alignItems='center' justifyContent='space-between'>
        {tiles.map(({ title, variant, count, onEdit }) => {
          const isStatusPill = variant === 'status'

          const statusPillProps = {
            mt: '1rem',
            size: 'large',
            variant: !isStatusPill
              ? 'count'
              : isActive
                ? 'success'
                : 'error'
          }

          return (
            <Flex
              key={title}
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              bg='grayscale.xxlight'
              height='8rem'
              width={tileWidth}
              borderRadius='small'
            >
              <Text fontSize='small' color='grayscale.xdark'>
                {title}
              </Text>

              <StatusPill {...statusPillProps}>
                <Flex
                  width='100%'
                  p='0 0.1rem'
                  justifyContent={onEdit ? 'space-between' : 'center'}
                  alignItems='center'
                  {...(onEdit && { pl: '1rem' })}
                >
                  {!isStatusPill ? count : isActive ? 'Active' : 'Inactive'}
                  {onEdit && (
                    <IconButton
                      icon='Pencil'
                      variant='clear'
                      onClick={onEdit}
                    />
                  )}
                </Flex>
              </StatusPill>
            </Flex>
          )
        })}
      </Flex>

    </StyledBox>
  )
}

AccountOverviewCard.defaultProps = {
  title: '',
  tiles: [],
  handleManageAccount: () => {}
}

export default AccountOverviewCard
