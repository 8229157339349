import { PureComponent } from 'react'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import toast from 'react-hot-toast'

import { PageProgressMethods } from '@fullfacing/ff-web-next-utils'
import initTableContext from '@fullfacing/react-table-context'
import {
  Flex,
  TableActions
} from '@fullfacing/schoolbus'

import Card from '@lib/cards/actions-card'
import AccountOverviewCard from '@lib/cards/account-overview-card'
import { Spinner, EmptyStateAlert } from '@lib/styled'
import { roles, HO_URL } from '@lib/config/constants'
import { List } from './styles'
import clientContainer from '@lib/containers/client'
import accountContainer from '@lib/containers/account'
import userContainer from '@lib/containers/user'
import { isSuperAdmin } from '@lib/utils/permissions'
import api from '@api'

const TwoStepDeleteModal = dynamic(() =>
  import('@lib/modals/two-step-delete-modal')
)
const AddAccountModal = dynamic(() => import('@lib/modals/add-account-modal'))
const FacilitiesModal = dynamic(() => import('@lib/modals/facilities-modal'))
const EditAccountModal = dynamic(() => import('@lib/modals/edit-account-modal'))
const ConfirmationModal = dynamic(() =>
  import('@lib/modals/confirmation-modal')
)

const [TableProvider, TableConsumer] = initTableContext(async args => {
  const { clientId: assignedClientId } = userContainer.state
  const { id: clientId } = clientContainer.state
  const { page, pageSize: limit } = args
  const offset = page * limit
  if (clientId !== assignedClientId && !isSuperAdmin()) {
    throw Error(`Invalid client request: ${clientId}`)
  }

  const { data: accountsResponse } = await api.accounts.getAccounts({
    params: {
      clientId,
      limit,
      offset,
      sort: 'name:asc'
    },
    raw: true
  })

  const { count, data: accounts } = accountsResponse
  const accountIds = accounts.map(({ id }) => id)

  const countsData = await api.users.getUserCount({
    clientId,
    accountIds,
    roles: [roles.learnerPermanent, roles.adminAccount]
  })

  const facilitiesData = accountIds.length
    ? await api.hardware.getState({ accountIds })
    : []

  const accountsData = accounts.map(account => {
    const { id: accountId } = account
    const count = countsData.find(item => item.accountId === accountId)?.count
    const activeLearners =
      count?.find(({ role }) => role === roles.learnerPermanent)?.enabled || 0
    const facility = facilitiesData.find(
      facility => facility.accountId === accountId
    )
    const { total: totalDevices = 0 } = facility || {}

    return {
      ...account,
      activeLearners,
      totalDevices
    }
  })

  const permissions = accounts.map(({ id: accountId, name }) => {
    const count = countsData.find(item => item.accountId === accountId)?.count
    const enabled =
      count?.find(({ role }) => role === roles.adminAccount)?.enabled || 0
    return { accountId, name, users: enabled }
  })

  const data = accountsData.map(account => {
    const permission = permissions.find(
      ({ accountId }) => accountId === account.id
    )
    return {
      ...account,
      ...(permission ? { adminUserCount: permission.users } : {})
    }
  })

  return {
    data,
    meta: { count }
  }
})

class AccountsCard extends PureComponent {
  static defaultProps = {
    isAdmin: false
  }

  state = {
    showAddAccountModal: false,
    showFacilitiesModal: false,
    objectToDelete: false,
    showDeleteModal: false,
    objectToActivate: false,
    selectedAccount: null,
    editAccountId: null
  }

  toggleLoading = (isLoading = false) => {
    PageProgressMethods[isLoading ? 'start' : 'done']()
    this.setState({ isLoading })
  }

  handlePermissionClick = async (id, name) => {
    await accountContainer.set({ id, name })
    Router.push('/groups')
  }

  handleAddAccountBtnClicked = () =>
    this.setState({ showAddAccountModal: !this.state.showAddAccountModal })

  handleManage = async ({ id, name, active, clientId }) => {
    const { isAdmin } = this.props

    try {
      const licenseFields = 'id,content,platforms'
      const licenseConfig = { accountId: id, fields: licenseFields }
      const { data: licenseData } = await api.licences.query(licenseConfig)
      const { data: licenses } = licenseData

      if (isAdmin && HO_URL) {
        window.open(
          `${HO_URL}/dashboard?clientId=${clientId}&accountId=${id}`,
          '_blank'
        )
      } else {
        await accountContainer.set({ id, name, active, licenses })
        Router.push('/dashboard')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async handleActivation ({ id, active }, refresh) {
    const { local, inherited } = active

    if (!inherited) {
      return toast(
        "Please activate the client before changing an account's state."
      )
    }
    this.toggleLoading(true)

    const updateStatus = local
      ? api.accounts.deactivateAccount(id)
      : api.accounts.activateAccount(id)

    try {
      await updateStatus
      refresh()
      toast('Sorted! The account status has been updated.')
    } catch (error) {
      console.error(error)
      toast.error(
        'We were unable to update the account status. Please try again.',
        { error, errorType: 'view' }
      )
    } finally {
      this.toggleLoading()
    }
  }

  handleDelete = async (id, refresh) => {
    await api.accounts.delete(id)
    refresh()
  }

  showDeleteModal = (id, name) => {
    const objectToDelete = { id, name }
    this.setState({
      showDeleteModal: true,
      objectToDelete
    })
  }

  showConfirmationModal = objectToActivate =>
    this.setState({ objectToActivate })

  handleCloseConfirmationModal = () => this.setState({ objectToActivate: false })

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      objectToDelete: false
    })
  }

  handleShowFacilitiesModal = id =>
    this.setState({
      showFacilitiesModal: true,
      selectedAccount: id
    })

  handleCloseFacilitiesModal = () =>
    this.setState({
      showFacilitiesModal: false
    })

  handleEditAccountModal = id => this.setState({ editAccountId: id })

  renderItem = rowData => {
    const { isAdmin } = this.props

    const {
      id,
      name,
      activeLearners = 0,
      totalDevices = 0,
      active,
      adminUserCount = 0
    } = rowData

    const { local, inherited } = active
    const isActive = local && inherited

    const options = [
      {
        name: 'Edit',
        onClick: () => this.handleEditAccountModal(id)
      },
      {
        name: isActive ? 'Deactivate' : 'Activate',
        onClick: () => this.showConfirmationModal({ id, name, active }),
        isAdmin: true
      },
      {
        name: 'Delete',
        onClick: () => this.showDeleteModal(id, name),
        isAdmin: true
      }
    ]
      .filter(Boolean)
      .filter(item => !item.isAdmin || (item.isAdmin && isAdmin))

    const tiles = [
      {
        title: 'Active learners',
        count: activeLearners
      },
      {
        title: 'Group training facilities',
        count: totalDevices,
        onEdit: !isAdmin && (() => this.handleShowFacilitiesModal(id))
      },
      {
        title: 'Account admins',
        count: adminUserCount,
        onEdit: !isAdmin && (() => this.handlePermissionClick(id, name))
      },
      {
        title: 'Account status',
        variant: 'status'
      }
    ]

    return (
      <AccountOverviewCard
        key={name}
        title={name}
        options={options}
        tiles={tiles}
        isActive={isActive}
        buttonLabel={isAdmin ? 'Manage in HO' : 'Manage account'}
        buttonOnClick={() => this.handleManage(rowData)}
        mt='1.125rem'
      />
    )
  }

  render () {
    const {
      showAddAccountModal,
      showFacilitiesModal,
      objectToDelete,
      showDeleteModal,
      objectToActivate,
      selectedAccount,
      editAccountId
    } = this.state

    const { isAdmin } = this.props

    return (
      <Card
        hideDivider
        title='Accounts'
        isViewState={!isAdmin}
        description='Add and manage accounts for this client.'
        onAddClick={this.handleAddAccountBtnClicked}
      >
        <TableProvider pageSize={5}>
          <TableConsumer>
            {tableProps => {
              const {
                refresh,
                isEmpty,
                isLoading,
                meta,
                data = []
              } = tableProps

              return (
                <>
                  <AddAccountModal
                    isOpen={showAddAccountModal}
                    onClose={this.handleAddAccountBtnClicked}
                    clientId={clientContainer.state.id}
                    clientName={clientContainer.state.name}
                    onSubmit={() => {
                      this.handleAddAccountBtnClicked()
                      refresh()
                    }}
                  />

                  <FacilitiesModal
                    isOpen={showFacilitiesModal}
                    onClose={this.handleCloseFacilitiesModal}
                    accounts={data}
                    initSelected={selectedAccount}
                  />

                  {isLoading
                    ? (
                      <Flex justify='center' p='2em'>
                        <Spinner />
                      </Flex>
                      )
                    : (
                      <List>
                        {!isEmpty
                          ? (
                              data.map(this.renderItem)
                            )
                          : (
                            <EmptyStateAlert>
                              There are no accounts for this client.{' '}
                              {isAdmin
                                ? 'Click on plus button to add a new account.'
                                : ''}
                            </EmptyStateAlert>
                            )}
                      </List>
                      )}

                  <TableActions
                    count={meta.count}
                    pageSize={5}
                    {...tableProps}
                  />

                  <TwoStepDeleteModal
                    isOpen={!!objectToDelete && showDeleteModal}
                    onClose={this.handleCloseDeleteModal}
                    type='Account'
                    onConfirm={() =>
                      this.handleDelete(objectToDelete?.id, refresh)}
                    name={objectToDelete?.name}
                  />

                  <ConfirmationModal
                    type='activation'
                    contentType='Account'
                    onConfirm={() =>
                      this.handleActivation(objectToActivate, refresh)}
                    onClose={this.handleCloseConfirmationModal}
                    isOpen={!!objectToActivate}
                    contentName={objectToActivate.name}
                    status={objectToActivate?.active?.local}
                  />

                  <EditAccountModal
                    id={editAccountId}
                    isOpen={!!editAccountId}
                    refreshAccountsTable={refresh}
                    onClose={() => this.handleEditAccountModal(null)}
                  />
                </>
              )
            }}
          </TableConsumer>
        </TableProvider>
      </Card>
    )
  }
}

export default AccountsCard
