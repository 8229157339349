import React from 'react'

import InnerContent from '@lib/styled/inner-content'

import ClientProfileCard from '@lib/clients/profile-card'
import AccountsCard from '@lib/clients/accounts-card'
import LicensesCard from '@lib/clients/licenses-card'

const Client = () => {
  return (
    <InnerContent>
      <ClientProfileCard />
      <AccountsCard />
      <LicensesCard />
    </InnerContent>
  )
}

export default Client
