import styled from 'styled-components'
import { Text } from '@fullfacing/schoolbus'

export const Header = styled(Text).attrs(props => ({
  fontSize: 'medium',
  fontWeight: 'extrabold',
  color: 'black',
  margin: '2rem 0 1.2rem',
  ...props,
}))`
  text-decoration: underline;
`

export const styleOverrides = {
  tableLoader: {
    marginTop: '2em'
  },
  select: {
    minWidth: '13rem'
  }
}
