import React, { Component } from 'react'
import dynamic from 'next/dynamic'
import { deepEqual } from 'fast-equals'
import toast from 'react-hot-toast'
import initTableContext from '@fullfacing/react-table-context'
import {
  Button,
  Card,
  Flex,
  SelectWithoutFormik,
  QuerySelectWithoutFormik
} from '@fullfacing/schoolbus'

import { handleAsyncFunctionLoad } from '@lib/utils/async-function-handler'
import clientContainer from '@lib/containers/client'
import { ACCESS_LEVELS } from '@lib/config/constants'
import userContainer from '@lib/containers/user'
import { isSuperAdmin } from '@lib/utils/permissions'
import api from '@api'

import { Header, styleOverrides } from './styles'

import {
  journeyItems,
  marketplaceOptions,
  platformItems,
  journeyAccessOptions,
  journeySelectDefault,
  learnerAccessTypes
} from './config'

import PlatformAccessCard from './components/platform-access-card'
import JourneyAccessCard from './components/access-card'

const EmptyStateAlert = dynamic(() => import('@lib/styled/empty-state-alert'))
const LearnerAccess = dynamic(() =>
  import('@lib/clients/licenses-card/components/learner-access')
)
const BoxLoader = import('@lib/clients/licenses-card/components/box-loader')
const InputLoader = dynamic(() =>
  import('@lib/clients/licenses-card/components/input-loader')
)
const LicenseLoading = dynamic(() =>
  import('@lib/clients/licenses-card/components/license-loading')
)

const [TableProvider, TableConsumer] = initTableContext(async (tableArgs) => {
  const { active, accountId } = tableArgs.filters || {}

  const { route, filters } = learnerAccessTypes.find(
    ({ label }) => label === active
  )

  if (!accountId) return { data: [], meta: { count: 0 } }

  const response = await route.query({ accountId, ...filters })
  const { data = [], count } = response?.data || {}

  return {
    data,
    meta: { count }
  }
})

class LicensesCard extends Component {
  state = {
    isLoading: false,
    licenseId: '',
    account: {},
    initialValues: {},
    values: {}
  }

  getAccounts = async ({ filters, ...otherProps } = {}) => {
    const { id: clientId } = clientContainer.state
    const { clientId: assignedClientId } = userContainer.state

    if (clientId !== assignedClientId && !isSuperAdmin()) {
      throw Error(`Invalid client request ${clientId}`)
    }

    const { data } = await api.accounts.query({
      ...otherProps,
      clientId,
      fields: 'id,name'
    })

    return data
  }

  getLicenses = async () => {
    const { account } = this.state
    const { value: accountId } = account
    const fields = 'id,content,platforms'

    try {
      const response = await api.licences.query({ accountId, fields })
      const { id, content = [], platforms = [] } = response?.data?.data || {}
      const values = { content, platforms }

      this.setState({
        licenseId: id,
        values,
        initialValues: { ...values },
        isLoading: false
      })
    } catch (error) {
      toast.error(
        'We were unable to fetch the license information for this account. Please try again',
        { error }
      )
    }
  }

  canUpdate = () => {
    const { values, initialValues } = this.state
    return !deepEqual(values, initialValues)
  }

  handleCancelClick = () =>
    this.setState(({ initialValues }) => ({ values: { ...initialValues } }))

  handleAccountSelect = (account, { filters, setFilters }) => {
    setFilters({ ...filters, accountId: account.value })
    this.setState({ account, isLoading: true }, this.getLicenses)
  }

  updateLicenses = async () => {
    const { licenseId, values } = this.state
    const { content, platforms } = values
    const data = { content, platforms }
    await api.licences.put(licenseId, data)
    this.setState({ initialValues: { ...values } })
  }

  onContentSelectChange = ({ value: accessLevel }, key) => {
    const { values } = this.state
    const { content: previousContent } = values
    const content = previousContent.map((item) =>
      item.type === key ? { ...item, accessLevel } : item
    )
    this.setState({ values: { ...values, content } })
  }

  handleCheckboxClicked = (keys) => {
    const { values } = this.state
    const { platforms = [] } = values

    const newPlatforms = platforms.some((platform) => keys.includes(platform))
      ? platforms.filter((item) => !keys.includes(item))
      : [...platforms, ...keys]

    this.setState({ values: { ...values, platforms: newPlatforms } })
  }

  handleCheckboxChange = (key, previousAccessLevel) => {
    const { noAccess, fullAccess } = ACCESS_LEVELS
    const accessLevel = previousAccessLevel === noAccess ? fullAccess : noAccess
    this.onContentSelectChange({ value: accessLevel }, key)
  }

  renderJourneyItems = ({ label, key }) => {
    const { values } = this.state
    const { isAdmin } = this.props
    const { noAccess } = ACCESS_LEVELS
    const { content = [] } = values
    const { accessLevel = noAccess } = content.length
      ? content.find(({ type }) => type === key)
      : {}

    const isActive = accessLevel !== noAccess

    const selectValue =
      journeyAccessOptions.find(({ value }) => value === accessLevel) ||
      journeySelectDefault

    return (
      <JourneyAccessCard
        key={key}
        label={label}
        isLocked={!isAdmin}
        isActive={isActive}
        selectOptions={journeyAccessOptions}
        selectDefaultValue={journeySelectDefault}
        selectValue={selectValue}
        handleSelect={(value) => this.onContentSelectChange(value, key)}
        handleCheckboxChange={() => this.handleCheckboxChange(key, accessLevel)}
      />
    )
  }

  renderPlatformItems = ({ label, key }) => {
    const { values } = this.state
    const { isAdmin } = this.props
    const { platforms = [] } = values
    const isActive = platforms.some((item) => key.includes(item))

    return (
      <PlatformAccessCard
        key={key}
        label={label}
        isLocked={!isAdmin}
        isActive={isActive}
        handleCheckboxChange={() => this.handleCheckboxClicked(key)}
      />
    )
  }

  render () {
    const { isAdmin } = this.props
    const { isLoading, account, values, licenseId, initialValues } = this.state

    const { platforms = [] } = values
    const { content: allowedContentTypes = [] } = initialValues || {}

    const isAccountSelected = Boolean(account?.value)

    return (
      <Card title='Licensing'>
        {isLoading && <LicenseLoading />}

        {!isLoading && (
          <TableProvider
            filters={{
              active: learnerAccessTypes[0].label,
              accountId: account.value
            }}
          >
            <TableConsumer>
              {(tableProps) => (
                <>
                  <Flex justify='space-between' height='2rem'>
                    <QuerySelectWithoutFormik
                      shape='rounded'
                      variant='light'
                      hasShadow
                      placeholder={account.label || 'Account'}
                      apiRequest={this.getAccounts}
                      mapResponse={(data) => data}
                      value={account.value}
                      onChange={(value) =>
                        handleAsyncFunctionLoad(
                          () => this.handleAccountSelect(value, tableProps),
                          'Failed to get the licencing data.'
                        )}
                    />

                    {!isLoading && isAdmin && this.canUpdate() && (
                      <Flex>
                        <Button
                          variant='secondary'
                          onClick={this.handleCancelClick}
                        >
                          Cancel
                        </Button>
                        <Button
                          ml='1em'
                          variant='primary'
                          onClick={() =>
                            handleAsyncFunctionLoad(
                              this.updateLicenses,
                              'Failed to update the licenses.'
                            )}
                        >
                          Save
                        </Button>
                      </Flex>
                    )}
                  </Flex>

                  {isAccountSelected && (
                    <>
                      <Header>Journey access</Header>
                      <Flex align='center' mr='-0.7rem' wrap>
                        {isLoading && <BoxLoader />}
                        {!isLoading &&
                          journeyItems.map(this.renderJourneyItems)}
                      </Flex>

                      <Header>Marketplace access</Header>
                      {isLoading && <InputLoader />}
                      {!isLoading && (
                        <SelectWithoutFormik
                          disabled={!isAdmin}
                          width='calc(25% - 0.7rem)'
                          variant='light'
                          hasShadow
                          onChange={() =>
                            this.handleCheckboxClicked(['Marketplace'])}
                          options={marketplaceOptions}
                          value={marketplaceOptions.find(
                            ({ value }) =>
                              value === platforms.includes('Marketplace')
                          )}
                          style={styleOverrides.select}
                        />
                      )}

                      <Header>Platform access</Header>
                      <Flex flexWrap='wrap' mr='-0.7rem'>
                        {isLoading && <InputLoader />}
                        {!isLoading &&
                          platformItems.map(this.renderPlatformItems)}
                      </Flex>

                      <Header>Learner access</Header>
                      <LearnerAccess
                        accountId={account.value}
                        licenceId={licenseId}
                        isAdmin={isAdmin}
                        tableProps={tableProps}
                        allowedContentTypes={allowedContentTypes}
                      />
                    </>
                  )}

                  {!isAccountSelected && (
                    <EmptyStateAlert m='2em 0 0'>
                      To view licensing details, please select an account from
                      the drop-down list.
                      {isAdmin && (
                        <>
                          <br />
                          If you do not see your account listed, click the '+'
                          button on the 'Accounts' card to create a new account.
                        </>
                      )}
                    </EmptyStateAlert>
                  )}
                </>
              )}
            </TableConsumer>
          </TableProvider>
        )}
      </Card>
    )
  }
}

export default LicensesCard
