import React from 'react'
import { Text, Checkbox, theme } from '@fullfacing/schoolbus'

import CheckCircle from '@fullfacing/material-icons/dist/CheckCircle'
import LockOutline from '@fullfacing/material-icons/dist/LockOutline'

import {
  Container,
  Label
} from './styles'

const { colors } = theme

const LABEL_PROPS = {
  whiteSpace: 'nowrap',
  fontWeight: 'bold'
}

const PlatformAccessCard = ({
  isActive,
  isLocked,
  handleCheckboxChange,
  label,
  children
}) => {
  const Icon = isLocked && isActive ? CheckCircle : LockOutline

  return (
    <Container isActive={isActive} isLocked={isLocked}>
      {isLocked && (
        <Label>
          <Icon color={isActive ? colors.primary.default : colors.grayscale.dark} />
          <Text
            fontSize='small'
            ml='0.5rem'
            {...LABEL_PROPS}
          >
            {label}
          </Text>
        </Label>
      )}

      {!isLocked && (
        <Checkbox
          p='0'
          checked={isActive}
          onChange={handleCheckboxChange}
          label='Select'
          labelProps={LABEL_PROPS}
        >
          {label}
        </Checkbox>
      )}
      {children && children}
    </Container>
  )
}

PlatformAccessCard.defaultProps = {
  label: '',
  selectOptions: []
}

export default PlatformAccessCard
